import { useEffect, useState } from 'react';
import './App.css';
import Heading from './components/Heading';
import CircularImg from './components/CircularImg';

function App() {

  const [name, setName] = useState("Mukul Sharma");
  
  const onButtonClick = ()=>{
    setName(name == "Ishu" ? "Mukul" : "Ishu");
  }

  useEffect(()=>{
    console.log("On Update")
  }, [name])

  useEffect(()=>{
    console.log("Once")

    return function(){

    }
  }, [])


  return (
    <>
      <Heading text={name}/>
      <CircularImg radius={"100px"} src={"https://miro.medium.com/max/2400/0*hDAyhnOx767w5qma.jpg"} width={"150px"}/>
      <button onClick={onButtonClick}>Change Name</button>
    </>
  );
}

export default App;
