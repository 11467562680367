
import {Col, Container,Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import TopNavBar from '../../components/TopNavBar/TopNavBar';
import './Home.css';
import Mainhead from '../../components/Mainhead/Mainhead';
import bg1 from '../../imgs/bg3.mp4';
import img from '../../imgs/2672292.png';
import react from '../../imgs/productImg/react.png';
import node from '../../imgs/productImg/nodejs.png';
import angular from '../../imgs/productImg/angular.png'
import db from '../../imgs/productImg/db.png'

function Home(){

    const whoweareText = "we are passionate about empowering individuals to thrive in the digital age. Our mission is to bridge the gap between ambition and achievement by providing world-class coding education tailored to the needs of today’s fast-evolving tech industry.";

    return(
        <>
            <section className='topSection'>
                <TopNavBar/>
                <Mainhead/> 
            </section>
            <section className='whoweare'>
                 <Container>
                     <Row>
                        <Col md={12}>
                            {/* <h1 className='imagehead'>
                                WHO WE ARE
                                <div className='underline'></div>
                            </h1> */}
                            <p className='introimg'>At RISHU.TECH, </p>
                            <p className='introimg'>{whoweareText}</p>
                        </Col>
                     </Row>
                 </Container>
            </section>
            <section className='thirdsection'>
                <div className='coursename'>OUR COURSES</div>
                <Container className='mt-4'>
                    <Row>
                        <Col>
                            <div className='myCard'>
                                <img src={react} className='cardImg'/>
                                <h3>React.js</h3>
                                <div className='underline'></div>
                                <ul>
                                    <li>React Lifecycle</li>
                                    <li>React Components</li>
                                    <li>React Hooks</li>
                                    <li>React Memo</li>
                                    <li>App Optimization</li>
                                    <li>Live Project</li>
                                </ul>
                                <button className='chooseBtn'>Choose This</button>
                            </div>
                        </Col>
                        <Col>
                            <div className='myCard'>
                                <img src={node} className='cardImg'/>
                                <h3>Node.js</h3>
                                <div className='underline'></div>
                                <ul>
                                    <li>React Lifecycle</li>
                                    <li>React Components</li>
                                    <li>React Hooks</li>
                                    <li>React Memo</li>
                                    <li>App Optimization</li>
                                    <li>Live Project</li>
                                </ul>
                                <button className='chooseBtn'>Choose This</button>
                            </div>
                        </Col>
                        <Col>
                            <div className='myCard'>
                                <img src={angular} className='cardImg'/>
                                <h3>Angular</h3>
                                <div className='underline'></div>
                                <ul>
                                    <li>React Lifecycle</li>
                                    <li>React Components</li>
                                    <li>React Hooks</li>
                                    <li>React Memo</li>
                                    <li>App Optimization</li>
                                    <li>Live Project</li>
                                </ul>
                                <button className='chooseBtn'>Choose This</button>
                            </div>
                        </Col>
                        <Col>
                            <div className='myCard'>
                                <img src={db} className='cardImg'/>
                                <h3>MongoDB</h3>
                                <div className='underline'></div>
                                <ul>
                                    <li>React Lifecycle</li>
                                    <li>React Components</li>
                                    <li>React Hooks</li>
                                    <li>React Memo</li>
                                    <li>App Optimization</li>
                                    <li>Live Project</li>
                                </ul>
                                <button className='chooseBtn'>Choose This</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>   
            <section className='footer'>
                    <p>Copyright © 2024 Rishu Tech. We Build Talent.</p>    
            </section>
        </>
    )
}
export default Home;