import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import UseMemoExample from './components/UseMemoExample';
import AddingNumber from './components/AddingNumber/AddingNumber';
import Table from './components/Table/Table';
import New from './components/New/New';
import Art from './components/Art/Art';
import Data from './components/Data/Data';
import TestPage from './components/TestPage/TestPage';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <TestPage/> */}
    <Home/>
    {/* 
    <AddingNumber/>
    <Table/>
    <New/>
    <Art/> */}
    {/* <Data/> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
