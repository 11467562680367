            
import {Container,Navbar,Nav,NavDropdown,Col,Row,} from "react-bootstrap";    
import './Mainhead.css';
import RishuBtn from "../RishuBtn/RishuBtn";
import { useEffect } from "react";

function Mainhead(){

    return(
        <>
            <Container>
                    <Row>
                        <Col className='courses'>
                            <h1 className='mainhead'>We are not only the coaching institute</h1>
                            <p  className='mainhead'>We are the <span className="coloredText">Talent Creaters</span></p>
                            <p className='mainheadintro'>We are completely tranform your career Jurney</p>
                            <RishuBtn text={'Find Best Course For You'} className='mt-5'/>
                        </Col>     
                    </Row>
            </Container>
        </>

    )
}
export default Mainhead;         
            
            
           