import { Container, Nav, Offcanvas, Navbar, Form, Button } from "react-bootstrap";
import RishuBtn from "../RishuBtn/RishuBtn";
import './TopNavBar.css';
import logo from '../../imgs/logo.png';

function TopNavBar() {
    const expandScreen = "lg";

    return (
        <>
            <Navbar key={expandScreen} expand={expandScreen}>
                <Container>
                    <Navbar.Brand href="#">
                        <img src={logo} className="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='navbar' />
                    <Navbar.Offcanvas
                        id="navbar"
                        aria-labelledby="navbarOffcanvas"
                        placement="start"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id='navbarOffcanvas'>
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-center flex-grow-1">
                                <Nav.Link href="#action1">Home</Nav.Link>
                                <Nav.Link href="#action2">Who We Are</Nav.Link>
                                <Nav.Link href="#action1">Courses</Nav.Link>
                                <Nav.Link href="#action2">How It Works</Nav.Link>
                                <Nav.Link href="#action1">Contact Us</Nav.Link>
                            </Nav>
                            <RishuBtn text="GET ENROLL" className='float-end d-md-none d-lg-block'/>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            {/* <Container>
                <Row className="navbarRow">
                    <Col md={2}>
                        <img src={logo} className="logo"/>
                    </Col>
                    <Col md={8} className="text-center">
                        <ul className='topnav'>
                            <li>Home</li>
                            <li>Who We Are</li>
                            <li>Courses</li>
                            <li>How It Works</li>
                            <li>Contact Us</li>
                        </ul>
                    </Col>
                    <Col md={2}>
                        <RishuBtn text="GET ENROLL" className='float-end d-md-none d-lg-block'/>
                    </Col>
                </Row>
            </Container> */}
        </>
    )
}

export default TopNavBar;